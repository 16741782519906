import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './App.css';
import SignIn from './components/SignIn.js'
import SignUp from './components/SignUp.js'
import Start from './components/Start.js'
import DataEditor from './components/DataEditor.js'
import Header from './components/Header.js'
import Calendar from './components/Calendar.js'
import Info from './components/Info.js'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getDatabase } from './components/tools/db.js';

import {
  BrowserRouter,
  MemoryRouter,
  Routes,
  Route,
  Navigate,
  Link
} from "react-router-dom";

import {
  selectCurrentUserId
} from './components/sliceDataEditor';

import {
  importUserFromDatabase
} from './components/sliceAccounts'

const theme = createTheme({
  overrides: {
    MuiPickerStaticWrapper:  {
      root: {
        backgroundColor: 'transparent'
      }
    }
  },
  palette: {
    primary: {
      main: "#FF2800"
    },
    secondary :{
      main: "#FF2800"
    }
  },
});

function App() {

  const dispatch = useDispatch()

  const currentUserId = useSelector(selectCurrentUserId);

  getDatabase().then( (db) => {
    let transaction = db.transaction(["users"]);
    let objectStore = transaction.objectStore("users");
    let dataRequest = objectStore.getAll();
    dataRequest.onsuccess = event => {
      const data = dataRequest.result;
      for(let key in data) {
        dispatch(importUserFromDatabase(data[key]))
      }
    }
  })

  let behindTheWall = <>
    <Route path="/dataeditor" element={<DataEditor />} />
    <Route path="/calendar" element={<Calendar />} />
  </>

  return (
    <ThemeProvider theme={theme}>
      <MemoryRouter>
        <div className="layoutContainer">
          <div className="layoutHeadRow">
            <Header />
          </div>
            <Routes>
              <Route path="/" element={<Start />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/info" element={<Info />} />
              {currentUserId?behindTheWall:null}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </div>
      </MemoryRouter>
    </ThemeProvider>
  )
}

export default App;
