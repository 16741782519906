import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from "react-router-dom";

import { useNavigate } from 'react-router-dom';

import {
  setCandidateUserName, setCandidatePassword, setCandidatePassword1, setCandidatePassword2, appendNewUser,
  selectCurrentUser, selectCandidatePasswordsMatch, selectCheckCredentials, importUserFromDatabase
} from './sliceAccounts'

import {
  logUserIn
} from './sliceDataEditor'

import { getDatabase, loadData } from './tools/db.js';

export default function SignIn() {

  const checkCredentials = useSelector(selectCheckCredentials);
  console.log(checkCredentials)
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleSignIn = () => {
    console.log("A")
    loadData(checkCredentials.id).then( (data) => {
      console.log("B")
      dispatch(logUserIn({
        id: checkCredentials.id,
        name: checkCredentials.name,
        data: data
      }))
      navigate('/dataeditor')
    }).catch( (e) => {
      console.log(e)
    })
    console.log("Huhu")
  };

  console.log("a")

  return (
    <div className="layoutMainRow">
      Please enter your name and password.
      <TextField
        autoFocus
        margin="dense"
        id="userName"
        label="Name"
        type="text"
        fullWidth
        variant="standard"
        onChange={e => dispatch(setCandidateUserName(e.target.value))}
      />
      <TextField
        margin="dense"
        id="pw1"
        label="Password"
        type="password"
        fullWidth
        variant="standard"
        onChange={e => dispatch(setCandidatePassword(e.target.value))}
      />
      <Button component={Link} to="/" variant="contained" color="secondary">Cancel</Button>&nbsp;&nbsp;
      <Button onClick={handleSignIn} variant="contained" color="secondary" disabled={!checkCredentials}>Log in</Button>
    </div>
  );
}
