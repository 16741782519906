import { createSlice } from '@reduxjs/toolkit';
import { v4, v5 } from 'uuid';
import { getDatabase } from './tools/db.js';
import dataSetDefinition from './dataSetDefinition';

// Derive empty data set from data set definition
function recursiveStripFields(i) {
  let unwanted = ['info','comment','type','options']
  for(let key in i) {
    if(unwanted.includes(key)) {
      delete i[key]
    } else if(typeof i[key] === 'object') {
      i[key]=recursiveStripFields(i[key])
    }
  }
  return i
}
let setData = JSON.parse(JSON.stringify(dataSetDefinition))
setData = recursiveStripFields(setData)

// Calculate dayID of today
let today = Math.floor(Date.now()/86400000)

// Initialize empy data set for a new user
let initData = {}
initData[today] = Object.assign({},setData)

// Analyze cycles
function detectCycles(data) {
  let dayIDs = Object.keys(data).sort()
  let firstID = parseInt(dayIDs[0])
  let lastID = parseInt(dayIDs[dayIDs.length-1])
  console.log(dayIDs)
  console.log({first:firstID})
  console.log({last:lastID})
  let cycles = {}
  let cyclecounter = 0
  let currentcycle = false
  for(let x=firstID; x<=lastID; x++) {
    if(data[x]!==undefined && data[x].bleeding.parameters.bleeding.value.length>0) {
      if(currentcycle===false) {
        currentcycle = cyclecounter
        cycles[currentcycle] = { start:x, end:x }
      } else {
        cycles[currentcycle].end=x
      }
    } else {
      if(currentcycle!==false) {
        if(cycles[cyclecounter].start+10<=x) {
          currentcycle = false
          cyclecounter++
        }
      }
    }
  }
  console.log(cycles)
  return cycles
}


export const slice = createSlice({
  name: 'editor',
  initialState: {
    currentUserId: false,
    currentUserName: false,
    today: today,
    currentDay: today,
    data: initData
  },
  reducers: {
    logUserIn: (state, action) => {
      state.currentUserName = action.payload.name
      state.currentUserId = action.payload.id
      let data = action.payload.data ? action.payload.data : Object.assign({},initData)
      state.data = data
      state.cycles = detectCycles(action.payload.data)
    },
    logOut: (state, action) => {
      state.currentUserName = false
      state.currentUserId = false
      state.data = Object.assign({},initData)
    },
    setCurrentDay: (state, action) => {
      state.currentDay = action.payload
    },
    incCurrentDay: (state, action) => {
      let dayID = state.currentDay+1
      if(state.data[dayID]===undefined) {
        state.data[dayID] = Object.assign({},setData)
      }
      state.currentDay = dayID
    },
    decCurrentDay: (state, action) => {
      let dayID = state.currentDay-1
      if(state.data[dayID]===undefined) {
        state.data[dayID] = Object.assign({},setData)
      }
      state.currentDay = dayID
    },
    setParameterValue: (state, action) => {
        state.data[state.currentDay][action.payload.block]['parameters'][action.payload.parameter].value=action.payload.value
        let currentUserId = state.currentUserId
        let jsonData = JSON.stringify(state.data)
        getDatabase().then( (db) => {
          let dataObjectStore = db.transaction("data", "readwrite").objectStore("data");
          dataObjectStore.put({
            id:currentUserId,
            data:jsonData
          });
        })
    }
  },
});

export const {
  logUserIn,
  logOut,
  setCurrentDay,
  incCurrentDay,
  decCurrentDay,
  setParameterValue
} = slice.actions;

export const selectCurrentUserName = state => state.editor.currentUserName;
export const selectCurrentUserId = state => state.editor.currentUserId;
export const selectToday = state => state.editor.today;
export const selectCurrentDay = state => state.editor.currentDay;
export const selectData = state => state.editor.data[state.editor.currentDay]; // rename this
export const selectAllData = state => state.editor.data; // rename this
export const selectCycles = state => state.editor.cycles;

export default slice.reducer;
