import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  setCandidateUserName, setCandidatePassword, setCandidatePassword1, setcCandidatePassword2
} from './sliceAccounts';

import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

// import styles from './loginStyles.css';

export default function Info() {

  return (
    <div>
      <div className="layoutSubRow"></div>
      <div className="layoutMainRow" style={{textAlign:'center'}}>
        cyca.app ist ein kostenloses und werbefreies Angebot der seige.digital GbR.<br />
        <br />
        Sämtliche von der App erfassten Daten
        verbleiben ausschließlich auf dem Endgerät des
        Anwenders bzw. der Anwenderin und
        werden weder an die seige.digital GbR
        noch sonstige Dritte übertragen.<br />
        <br />
        Der Quellcode von cyca.app steht unter https://github.com/leanderseige/cyca für die Nachnutzung zur Verfügung.<br />
        <br />
        <Button component={Link} to="/" variant="contained" color="secondary">Back</Button><br />
      </div>
    </div>
  );
}
