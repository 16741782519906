import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from "react-router-dom";

import { useNavigate } from 'react-router-dom';


import {
  setCandidateUserName, setCandidatePassword, setCandidatePassword1, setCandidatePassword2, appendNewUser,
  selectCurrentUser, selectCandidatePasswordsMatch
} from './sliceAccounts';

export default function SignUp() {

  const pwmatch = useSelector(selectCandidatePasswordsMatch);

  const dispatch = useDispatch();

  let navigate = useNavigate();

  const handleSignUp = () => {
    dispatch(appendNewUser())
    navigate('/')
  };

  return (
    <div className="layoutMainRow">
      Please enter your Name. This can name can be pure fantasy, it doesn't have to be your real name.
      <TextField
        autoFocus
        margin="dense"
        id="userName"
        label="Name"
        type="text"
        fullWidth
        variant="standard"
        onChange={e => dispatch(setCandidateUserName(e.target.value))}
      />
      Passwords must equal. ({pwmatch?"OK":"Not OK"})
      <TextField
        margin="dense"
        id="pw1"
        label="Password"
        type="password"
        fullWidth
        variant="standard"
        onChange={e => dispatch(setCandidatePassword1(e.target.value))}
      />
      <TextField
        error={pwmatch?false:true}
        margin="dense"
        id="pw2"
        label="Password (repeat)"
        type="password"
        fullWidth
        variant="standard"
        onChange={e => dispatch(setCandidatePassword2(e.target.value))}
      />
      <Button component={Link} to="/login" variant="contained" color="secondary">Cancel</Button>&nbsp;
      <Button onClick={handleSignUp} variant="contained" color="secondary" disabled={!pwmatch}>Register</Button>
    </div>
  );
}
