import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import {
  setParameterValue,
  selectData
} from './sliceDataEditor';

import dataSetDefinition from './dataSetDefinition';

export default function UIMultiBoolean(props) {

  const dispatch = useDispatch();
  const data = useSelector(selectData);

  let parameter = dataSetDefinition[props.block]['parameters'][props.parameter]
  let buttons = []

  for(let b of parameter.options) {
    buttons.push(
      <ToggleButton value={b} key={`key-${props.block}-${props.parameter}-${b}`} style={{margin:"4px",border:"1px solid #ccc",borderRadius:"4px"}}>
        {b}
      </ToggleButton>
    )
  }

  return (
    <ToggleButtonGroup
      style={{ flexWrap: "wrap" }}
      exclusive={parameter.exclusive}
      value={data[props.block]['parameters'][props.parameter].value}
      onChange={ (e,v) => {
          console.log(v)
          dispatch(setParameterValue({ block:props.block, parameter:props.parameter, value:v }))
        }
      }
    >
    {buttons}
    </ToggleButtonGroup>
  );
}
