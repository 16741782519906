import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import { Link } from "react-router-dom";

import {
  logOut, selectCurrentUserId
} from './sliceDataEditor';

export default function BurgerMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const currentUserId = useSelector(selectCurrentUserId);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    dispatch(logOut())
  };

  return (
    <div style={{float:'right'}}>
      <IconButton
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled={!currentUserId} onClick={handleClose} component={Link} to='/dataeditor'>Data Editor</MenuItem>
        <MenuItem disabled={!currentUserId} onClick={handleClose} component={Link} to='/calendar'>Calendar</MenuItem>
        <MenuItem disabled={!currentUserId} onClick={handleLogOut} component={Link} to='/'>Log out</MenuItem>
        <MenuItem onClick={handleLogOut} component={Link} to='/info'>Info</MenuItem>
      </Menu>
    </div>
  );
}
