import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import isWeekend from 'date-fns/isWeekend';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import DatePicker from "@mui/lab/DatePicker";
import PickersDay, {
  PickersDayProps,
  pickersDayClasses
} from "@mui/lab/PickersDay";
import addDays from "date-fns/addDays";
import isSameDay from "date-fns/isSameDay";

import {
  selectAllData, selectCycles, setCurrentDay
} from './sliceDataEditor';

import Button from '@mui/material/Button';

export default function Calendar() {
  const [value, setValue] = React.useState(new Date());

  const data = useSelector(selectAllData);
  const cycles = useSelector(selectCycles);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    let colors = {
      spotting: '#faa',
      light: '#f88',
      medium: '#f66',
      dark: '#f44',
    }
    let dayID=Math.floor(date.getTime()/86400000)
    let bg = null
    if(dayID in data) {
      if(data[dayID].bleeding.parameters.bleeding.value.length>0) {
        bg={backgroundColor:colors[data[dayID].bleeding.parameters.bleeding.value]}
      }
    }
    let bd = null
    for(let c in cycles) {
      if(cycles[c].start<=dayID && cycles[c].end>=dayID) {
        bd={border:'3px solid red'}
      }
    }
    return (
      <PickersDay
        {...pickersDayProps}
        sx={Object.assign({},bg,bd)}
      />
    )
  }

  return (
    <div className="layoutMainRow">
    <LocalizationProvider dateAdapter={AdapterDateFns} style={{backgroundColor:'transparent'}}>
      <StaticDatePicker
        orientation="portrait"
        openTo="day"
        renderDay={renderWeekPickerDay}
        allowSameDateSelection={true}
        onChange={(newValue) => {
          let dayID = Math.floor(newValue.getTime()/86400000)
          navigate("/dataeditor");
          dispatch(setCurrentDay(dayID))
        }}
        renderInput={(params) => <TextField {...params} />}

      />
    </LocalizationProvider>
    </div>
  );
}
