import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Textfield from '@mui/material/TextField';

import {
  setParameterValue,
  selectData
} from './sliceDataEditor';

import dataSetDefinition from './dataSetDefinition';

export default function UIText(props) {

  const dispatch = useDispatch();
  const data = useSelector(selectData);

  let value = data[props.block]['parameters'][props.parameter].value || ''

  useEffect( () => {
    return () => {
      dispatch(setParameterValue({ block:props.block, parameter:props.parameter, value:value }))
    }
  })

  return (
    <Textfield
      defaultValue={value}
      type="text"
      inputProps={{}}
      onChange={ (e) => { value=e.target.value } }
    />
  )
}
