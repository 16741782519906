let dataSetDefinition = {
  bleeding: {
    name: "Bleeding",
    comment: false,
    info: "Tracking menstrual bleeding allows you to know the beginning and the end of a menstrual cycle.",
    exclude: false,
    parameters: {
      bleeding: {
        name: 'bleeding',
        comment: "How heavy is the bleeding?",
        type: 'multiboolean',
        exclusive: true,
        options: ['spotting','light','medium','heavy'] ,
        value: []
      }
    }
  },
  temperature: {
    name: "Temperature",
    comment: false,
    info: "One of the body signs...",
    exclude: false,
    parameters: {
      temperature: {
        name: 'temperature',
        comment: "Take your temperature right after waking up, before getting out of bed.",
        type: 'float',
        value: false
      },
      time: {
        name: 'time',
        comment: "Time",
        type: 'clocktime',
        value: false
      },
      note: {
        name: 'note',
        comment: "Is there anything that could have influenced this value, such as bad sleep or alcohol consumption?",
        type: 'text',
        value: false
      }
    }
  },
  cervicalmucus:{
    name: 'Cervical Mucus',
    comment: false,
    info: "Cervical can help...",
    exclude: false,
    parameters: {
      feeling: {
        name: 'feeling',
        comment: 'What does your vaginal entrance feel like?',
        type: 'multiboolean',
        exclusive: true,
        options: ['dry','nothing','wet','slippery'],
        value: []
      },
      description: {
        name: 'description',
        comment: 'Looking at and touching your cervical mucus, which describes it best?',
        type: 'multiboolean',
        exclusive: true,
        options: ['nothing','creamy','egg white'],
        value: []
      }
    }
  },
  cervix: {
    name: 'Cervix',
    comment: false,
    info: "Cervical can help...",
    exclude: false,
    parameters: {
      open: {
        name: 'open',
        comment: 'Is your cervix open or closed?',
        type: 'multiboolean',
        exclusive: true,
        options: ['closed','medium','open'],
        value: []
      },
      hard: {
        name: 'hard',
        comment: 'When it\'s hard it might feel like the tip of your nose.',
        type: 'multiboolean',
        exclusive: true,
        options: ['hard','soft'],
        value: []
      },
      high: {
        name: 'hard',
        comment: 'How high up in the vagina is the cervix?',
        type: 'multiboolean',
        exclusive: true,
        options: ['low','medium','high'],
        value: []
      }
    }
  },
  sex: {
    name: 'Sex',
    comment:false,
    info: 'The app allows you to track sex independently...',
    exclude: false,
    parameters: {
      activity: {
        name: 'activity',
        comment: 'Were you sexually active today?',
        type: 'multiboolean',
        exclusive: false,
        options: ['solo','partner'],
        value: []
      },
      contraceptives: {
        name: 'contraceptives',
        comment: 'Did you use contraceptives',
        type: 'multiboolean',
        exclusive: false,
        options: ['condom','pill','iud','patch','ring','implant','diaphragm','none','other'],
        value: []
      }
    }
  }
}

export default dataSetDefinition
