export function getDatabase() {
  return new Promise((resolve, reject) => {
    // window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB
    // window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction || {READ_WRITE: "readwrite"}
    // window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange
    if (!window.indexedDB) {
      console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.")
    }

    let request = window.indexedDB.open("CYCADB", 1)

    request.onupgradeneeded = function(event) {
      console.log("Creating new database.")
      let db = event.target.result
      let objectStoreUsers = db.createObjectStore("users", { keyPath: "id" })
      let objectStoreData = db.createObjectStore("data", { keyPath: "id" })
    }

    request.onerror = function(event) {
      console.log({error:event})
    }

    request.onsuccess = function(event) {
      let db = event.target.result
      resolve(db)
    }

  })
}

export function loadData(userId) {
  return new Promise((resolve, reject) => {
    getDatabase().then( (db) => {
      console.log({userId:userId})
      let dataObjectStore = db.transaction("data", "readwrite").objectStore("data");
      let req = dataObjectStore.get(userId);
      req.onerror = function(event) {
          resolve("Error")
      }
      req.onsuccess = function(event) {
        if(req.result===undefined) {
          resolve(false)
        } else {
          let data = JSON.parse(req.result.data)
          delete data.cycles
          resolve(data)
        }
      }
    })
  })
}
