import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';

import {
  setCurrentDay, incCurrentDay, decCurrentDay,
  selectToday, selectCurrentDay, selectData
} from './sliceDataEditor';

import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

import DataEditorPopup from './DataEditorPopup';

import dataSetDefinition from './dataSetDefinition';

export default function DataEditor() {

  const dispatch = useDispatch();

  const today = useSelector(selectToday);
  const currentDay = useSelector(selectCurrentDay);
  const data = useSelector(selectData);

  const [openDialog, setOpenDialog] = useState(false);

  let editor = Object.keys(dataSetDefinition).map( (k,i) =>
    <Grid item xs={6} key={`key-${dataSetDefinition[k].name}-${i}`}>
      <Card>
        <CardContent>
          <Typography variant="h6" component="div">
            {dataSetDefinition[k].name}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            { data ? Object.keys(data[k].parameters).map( (x) => <span>{data[k].parameters[x].value}</span>) : '' }
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setOpenDialog(k)}>Edit</Button>
        </CardActions>
      </Card>
    </Grid>
  )

  return (
    <div>

      <div className="layoutSubRow">
        <IconButton onClick={() => dispatch(decCurrentDay())} sx={{p:'16.5px'}}>
          <RemoveCircleOutlineIcon />
        </IconButton>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            label="Select Date"
            inputFormat="MM/dd/yyyy"
            value={currentDay*86400000}
            onChange={(v) => {
              let dayID = Math.floor(v.getTime()/86400000)
              dispatch(setCurrentDay(dayID))
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <IconButton onClick={() => dispatch(incCurrentDay())} sx={{p:'16.5px'}}>
          <AddCircleOutlineIcon />
        </IconButton>
      </div>

      <div className="layoutMainRow">
        <Box sx={{ width: '100%' }}>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3, md: 3 }} sx={{p:"16.5px"}}>
          {editor}
          </Grid>
        </Box>
      </div>

      <Dialog open={openDialog!==false}>
        <DataEditorPopup block={openDialog} callback={() => setOpenDialog(false)} />
      </Dialog>

    </div>
  );
}
