import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../logo.png';

import {
  setCandidateUserName, setCandidatePassword, setCandidatePassword1, setcCandidatePassword2
} from './sliceAccounts';

import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

// import styles from './loginStyles.css';

export default function Start() {

  return (
    <div>
    <div className="layoutSubRow">
      <img src={logo} style={{height:'100px'}} /><br />beta
    </div>
    <div className="layoutMainRow" style={{textAlign:'center'}}>
      <br />
      <br />
      Welcome!<br />
      <br />
      <br />
      <br />
      Sign in if you already have an account<br />
      <br />
      <Button component={Link} to="/signin" variant="contained" color="secondary">Sign in</Button><br />
      <br />
      <br />
      <br />
      Or register a new one<br />
      <br />
      <Button component={Link} to="/signup" variant="contained" color="secondary">Register</Button>
    </div>
    </div>
  );
}
