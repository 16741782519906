import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import UIMultiBoolean from './UIMultiBoolean'
import UIFloat from './UIFloat'
import UIClockTime from './UIClockTime'
import UIText from './UIText'

import {
  setCurrentDay,
  selectToday, selectCurrentDay
} from './sliceDataEditor';

import { Link } from "react-router-dom";

import dataSetDefinition from './dataSetDefinition';

export default function DataEditorPopup(props) {

  if(!props.block) {
    return null
  }

  let block = dataSetDefinition[props.block]
  let dc = []
  for(let key in block.parameters) {
    let p = []
    if(block.parameters[key].comment) {
      p.push(<Typography key={`block-${props.block}-${key}`}>{block.parameters[key].comment}</Typography>)
    }
    switch(block.parameters[key].type) {
      case 'multiboolean':
        p.push(<UIMultiBoolean block={props.block} parameter={key} key={v4()}/>)
        break
      case 'float':
        p.push(<UIFloat block={props.block} parameter={key} key={v4()}/>)
        break
      case 'clocktime':
        p.push(<UIClockTime block={props.block} parameter={key} key={v4()}/>)
        break
      case 'text':
        p.push(<UIText block={props.block} parameter={key} key={v4()}/>)
        break
      default:
        break
    }
    dc.push(p)
  }

  return (
    <div>
      <DialogTitle>{dataSetDefinition[props.block].name}</DialogTitle>
      <DialogContent>
      {dc}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.callback}>Close</Button>
      </DialogActions>
    </div>
  );
}
