import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './components/sliceAccounts';
import dataEditorReducer from './components/sliceDataEditor';

export default configureStore({
  reducer: {
    accounts: accountReducer,
    editor: dataEditorReducer
  },
});
