import { createSlice } from '@reduxjs/toolkit';
import { v4, v5 } from 'uuid';
import { getDatabase } from './tools/db.js';

const NS = 'c8fe49f2-78fc-4287-ab7a-e461927f7934'

export const slice = createSlice({
  name: 'accounts',
  initialState: {
    candidateUserName: false,
    candidatePassword: false,
    candidatePassword1: false,
    candidatePassword2: false,
    allUsers: {}
  },
  reducers: {
    setCandidateUserName: (state, action) => {
      state.candidateUserName = action.payload
    },
    setCandidatePassword: (state, action) => {
      state.candidatePassword = v5(action.payload, NS)
    },
    setCandidatePassword1: (state, action) => {
      state.candidatePassword1 = v5(action.payload, NS)
    },
    setCandidatePassword2: (state, action) => {
      state.candidatePassword2 = v5(action.payload, NS)
    },
    importUserFromDatabase: (state, action) => {
      let temp = Object.assign({},state.allUsers)
      temp[action.payload.id] = action.payload
      state.allUsers = temp
    },
    appendNewUser: (state, action) => {
      if(state.candidatePassword1===state.candidatePassword2) {
        let newUser = {
          id: v4(),
          name: state.candidateUserName,
          password: state.candidatePassword2
        }
        let temp = Object.assign({},state.allUsers)
        temp[newUser.id] = newUser
        state.allUsers = temp
        getDatabase().then( (db) => {
          let dataObjectStore = db.transaction("users", "readwrite").objectStore("users");
          dataObjectStore.add(newUser)
        })
      }
    },
  },
});

export const {
  setCandidateUserName,
  setCandidatePassword,
  setCandidatePassword1,
  setCandidatePassword2,
  appendNewUser,
  importUserFromDatabase
} = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const selectCandidatePasswordsMatch = state => state.accounts.candidatePassword1===state.accounts.candidatePassword2;

export const selectCheckCredentials = state => {
  for(let key in state.accounts.allUsers) {
    if(
      state.accounts.allUsers[key].name===state.accounts.candidateUserName
      && state.accounts.allUsers[key].password===state.accounts.candidatePassword
    ) {
      return {
        id: state.accounts.allUsers[key].id,
        name: state.accounts.candidateUserName
      }
    }
  }
  return false
}

export default slice.reducer;
