import React, { useState } from 'react';
import BurgerMenu from './BurgerMenu.js'
import logo from '../logo.png';
import {
  useLocation
} from "react-router-dom";

export default function Header() {

  const location = useLocation();

  return (
    <div style={{width:'100%'}}>
      {location.pathname==='/'?null:<img src={logo} style={{height:'50px',paddingTop:'5px'}}/>}
      <BurgerMenu />
    </div>
  );
}
